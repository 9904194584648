/* ================== Flexbox Classes ============== */

.flex-aic {
  display: flex;
  align-items: center;
}
.flex-aicjcc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-aicjcsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-aifsjcsb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-aicjccfdc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexgap1 {
  gap: 1rem;
}
.flexgap2 {
  gap: 2rem;
}
.flexgap3 {
  gap: 3rem;
}
.flexgap4 {
  gap: 4rem;
}

/* =================== margin padding ============== */

.mar-tb1 {
  margin: 1rem 0;
}
.mar-tb15 {
  margin: 1.5rem 0;
}
