.body {
    font-family: 'Outfit', sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 600px;
    margin: 0 auto;
}

.header {
    padding: 20px;
}

.emailOwner {
    font-weight: 300;
    font-size: 40px;
}

.welcomeText {
    font-size: 25px;
    font-weight: 300;
}

.darkParagraph {
    font-weight: 300;
    font-size: 20px;
    max-width: 442px;
    margin: 0 auto;
}

.heroimg {
    max-width: 300px;
}


.content {
    padding: 20px;
}

.actionBtn {
    padding: 10px 50px;
    border-radius: 40px;
    border: none;
    background-color: #FD096F;
    color: white;
    font-size: 20px;
    margin: 24px 0;
}

.lightParagraph {
    color: #00172D80;
    max-width: 420px;
    margin: 0 auto 20px;

}

.TeamTextbold {
    font-weight: 500;
    font-size: 25px;
}

.MedicalCirclesLogo {
    max-width: 332px;
}

.supportText {
    font-weight: 400;
    font-size: 20px;
    color: #00172D;
}

.supportText a {
    color: #00172D;
}

.footer {
    padding: 10px;
    font-weight: 300;
    color: #00172D;
    font-size: 20px;
}

.footer a {

    color: #00172D;
}

.footerAddress {
    font-weight: 300;
    font-size: 20px;
    color: #00172D80;
}

.socialMediaGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMediaLinks {
    max-width: 35px;
    margin: 10px;
}
