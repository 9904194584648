.centaloverviewparent{
    min-height: 100vh;
    height: 100%;
    background-color: #fafafa;
    /* width: 53vw; */
    padding: 2rem;
    margin-left: -2rem;
    margin-top: -2rem;
}

.sectionHeading{
    margin: 1.5rem  0 .5rem;
    margin-left: .7rem;
}
.ProgressCardsSliderArea{
    max-width:100%;
}


.slick-slide > div{
    margin: 0 .5rem;
}