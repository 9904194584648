

.ProgressCardBgImg {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
