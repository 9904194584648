.DetailedProfileParent {
  background-color: #f5f5f7;
  min-height: max-content;
}
.WhiteCardBox {
  background-color: white;
  border-radius: 0.7rem;
  padding: 1.5rem;
}

.ProfessionalDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.DetailsTabs {
  border-left: 3px solid black;
  padding: 0.3rem 1rem;
}

.TabHeading {
  text-transform: capitalize;
}
