.custom-button {
  background-color: #546FFF;
  color: white;
  outline: none;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  width: 100%;
  font-size: .9rem;
  font-weight: 600;
}
